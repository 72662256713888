@tailwind base;
@tailwind components;
@tailwind utilities;
@import "flatpickr/dist/flatpickr.min.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap"); */
/* 
@layer components {
  #__next {
    @apply h-full;
  }
  html,
  body {
    @apply h-full;
  }
} */

a {
  color: inherit;
  text-decoration: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-family: "Poppins", sans-serif;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.tooltip:hover {
  visibility: visible;
}

.layout_container {
  width: 100vw;
  min-height: 80vh;
  background-color: #ffffff;
  overflow-x: none;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 13px;
}

.scrollbar::-webkit-scrollbar-track {
  width: 3px;
  border-radius: 5px;
  background: #f0f0f0;
  padding: 2px;
}

.scrollbar::-webkit-scrollbar-thumb {
  width: 3px;
  background: #d3d3d3;
  border-radius: 5px;
  margin: 2px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc;
}

.error_input_text {
  color: #ff562c;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  top: 4px;
  left: 5px;
}

.error_input_text_form {
  color: #ff562c;
  font-size: 11px;
  font-weight: 500;
  position: relative;
  top: 4px;
  left: 5px;
}

.primary_submit {
  width: 100%;
  height: 42px;
  display: grid;
  place-items: center;
  background-color: #a55eea;
  margin: 5px auto;
  cursor: pointer;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
}

.secondary_submit {
  width: 100%;
  height: 42px;
  display: grid;
  place-items: center;
  background-color: #a4a4a4;
  margin: 10px auto;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
}

.go_back_btn {
  width: 90px;
  height: 25px;
  display: grid;
  grid-template-columns: 0.7fr 2fr;
  place-items: center;
  background-color: #fff;
  color: #a55eea;
  margin: 4px auto;
  margin-top: -15px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

.loading_container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #0000001d;
  z-index: 12;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #9fa0a1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.connections::-webkit-scrollbar {
  display: none;
}

.connections {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Customise flatpickr */
* {
  --calendarPadding: 24px;
  --daySize: 36px;
  --daysWidth: calc(var(--daySize) * 7);
}

.bgWhite {
  background: white;
  box-shadow: 0px 3px 6px 0px #cacaca;
}

.customBtn {
  border-radius: 0px;
  padding: 10px;
}

/* form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
} */

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -8px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@layer components {
  .flatpickr-calendar {
    border: inherit;
    @apply rounded shadow-lg border border-slate-200 left-1/2 font;
    margin-left: calc(
      calc(var(--daysWidth) + calc(var(--calendarPadding) * 2)) * 0.5 * -1
    );
    padding: var(--calendarPadding);
    width: calc(var(--daysWidth) + calc(var(--calendarPadding) * 2));
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }

  @screen lg {
    .flatpickr-calendar {
      @apply left-0 right-auto;
      margin-left: 0;
    }
  }

  .flatpickr-wrapper {
    display: flex !important;
  }

  .flatpickr-right.flatpickr-calendar {
    @apply right-0 left-auto;
    margin-left: 0;
  }

  .flatpickr-calendar.animate.open {
    animation: fpFadeInDown 200ms ease-out;
  }

  .flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 4px);
  }

  .flatpickr-calendar.static.open {
    z-index: 20;
  }

  .flatpickr-days {
    width: var(--daysWidth);
  }

  .dayContainer {
    width: var(--daysWidth);
    min-width: var(--daysWidth);
    max-width: var(--daysWidth);
  }

  .flatpickr-day {
    @apply bg-slate-50 text-sm font-medium text-slate-600;
    max-width: var(--daySize);
    height: var(--daySize);
    line-height: var(--daySize);
  }

  .flatpickr-day,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay {
    border: none;
  }

  .flatpickr-day,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange,
  .flatpickr-day.endRange.startRange,
  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange,
  .flatpickr-day.selected.startRange.endRange,
  .flatpickr-day.startRange.startRange.endRange,
  .flatpickr-day.endRange.startRange.endRange {
    border-radius: 0;
  }

  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay {
    @apply text-slate-400;
  }

  .rangeMode .flatpickr-day {
    margin: 0;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    @apply bg-primary text-indigo-50;
  }

  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus,
  .flatpickr-day.today:hover,
  .flatpickr-day.today:focus {
    @apply bg-primary text-indigo-50;
  }

  .flatpickr-day.inRange,
  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    box-shadow: none;
  }

  .flatpickr-months {
    align-items: center;
    margin-top: -8px;
    margin-bottom: 6px;
  }

  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    position: static;
    height: auto;
    @apply text-slate-600;
  }

  .flatpickr-months .flatpickr-prev-month svg,
  .flatpickr-months .flatpickr-next-month svg {
    width: 7px;
    height: 11px;
  }

  .flatpickr-months .flatpickr-prev-month:hover,
  .flatpickr-months .flatpickr-next-month:hover,
  .flatpickr-months .flatpickr-prev-month:hover svg,
  .flatpickr-months .flatpickr-next-month:hover svg {
    fill: inherit;
    @apply text-slate-400;
  }

  .flatpickr-months .flatpickr-prev-month {
    margin-left: -10px;
  }

  .flatpickr-months .flatpickr-next-month {
    margin-right: -10px;
  }

  .flatpickr-months .flatpickr-month {
    @apply text-slate-800;
    height: auto;
    line-height: inherit;
  }

  .flatpickr-current-month {
    @apply text-sm font-medium;
    position: static;
    height: auto;
    width: auto;
    left: auto;
    padding: 0;
  }

  .flatpickr-current-month span.cur-month {
    @apply font-medium m-0;
  }

  .flatpickr-current-month span.cur-month:hover {
    background: none;
  }

  .flatpickr-current-month input.cur-year {
    font-weight: inherit;
    box-shadow: none !important;
  }

  .numInputWrapper:hover {
    background: none;
  }

  .numInputWrapper span {
    display: none;
  }

  span.flatpickr-weekday {
    @apply text-slate-400 font-medium text-xs;
  }

  .flatpickr-calendar.arrowTop::before,
  .flatpickr-calendar.arrowTop::after,
  .flatpickr-calendar.arrowBottom::before,
  .flatpickr-calendar.arrowBottom::after {
    display: none;
  }
}
@layer components {
  .flatpickr-calendar {
    border: inherit;
    @apply rounded shadow-lg border border-slate-200 left-1/2 font;
    margin-left: calc(
      calc(var(--daysWidth) + calc(var(--calendarPadding) * 2)) * 0.5 * -1
    );
    padding: var(--calendarPadding);
    width: calc(var(--daysWidth) + calc(var(--calendarPadding) * 2));
  }

  @screen lg {
    .flatpickr-calendar {
      @apply left-0 right-auto;
      margin-left: 0;
    }
  }

  .flatpickr-wrapper {
    display: flex !important;
  }

  .flatpickr-right.flatpickr-calendar {
    @apply right-0 left-auto;
    margin-left: 0;
  }

  .flatpickr-calendar.animate.open {
    animation: fpFadeInDown 200ms ease-out;
  }

  .flatpickr-calendar.static {
    position: absolute;
    top: calc(100% + 4px);
  }

  .flatpickr-calendar.static.open {
    z-index: 20;
  }

  .flatpickr-days {
    width: var(--daysWidth);
  }

  .dayContainer {
    width: var(--daysWidth);
    min-width: var(--daysWidth);
    max-width: var(--daysWidth);
  }

  .flatpickr-day {
    @apply bg-slate-50 text-sm font-medium text-slate-600;
    max-width: var(--daySize);
    height: var(--daySize);
    line-height: var(--daySize);
  }

  .flatpickr-day,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay {
    border: none;
  }

  .flatpickr-day,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange,
  .flatpickr-day.endRange.startRange,
  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange,
  .flatpickr-day.selected.startRange.endRange,
  .flatpickr-day.startRange.startRange.endRange,
  .flatpickr-day.endRange.startRange.endRange {
    border-radius: 0;
  }

  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay {
    @apply text-slate-400;
  }

  .rangeMode .flatpickr-day {
    margin: 0;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    @apply bg-primary text-indigo-50;
  }

  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus,
  .flatpickr-day.today:hover,
  .flatpickr-day.today:focus {
    @apply bg-primary text-indigo-50;
  }

  .flatpickr-day.inRange,
  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    box-shadow: none;
  }

  .flatpickr-months {
    align-items: center;
    margin-top: -8px;
    margin-bottom: 6px;
  }

  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    position: static;
    height: auto;
    @apply text-slate-600;
  }

  .flatpickr-months .flatpickr-prev-month svg,
  .flatpickr-months .flatpickr-next-month svg {
    width: 7px;
    height: 11px;
  }

  .flatpickr-months .flatpickr-prev-month:hover,
  .flatpickr-months .flatpickr-next-month:hover,
  .flatpickr-months .flatpickr-prev-month:hover svg,
  .flatpickr-months .flatpickr-next-month:hover svg {
    fill: inherit;
    @apply text-slate-400;
  }

  .flatpickr-months .flatpickr-prev-month {
    margin-left: -10px;
  }

  .flatpickr-months .flatpickr-next-month {
    margin-right: -10px;
  }

  .flatpickr-months .flatpickr-month {
    @apply text-slate-800;
    height: auto;
    line-height: inherit;
  }

  .flatpickr-current-month {
    @apply text-sm font-medium;
    position: static;
    height: auto;
    width: auto;
    left: auto;
    padding: 0;
  }

  .flatpickr-current-month span.cur-month {
    @apply font-medium m-0;
  }

  .flatpickr-current-month span.cur-month:hover {
    background: none;
  }

  .flatpickr-current-month input.cur-year {
    font-weight: inherit;
    box-shadow: none !important;
  }

  .numInputWrapper:hover {
    background: none;
  }

  .numInputWrapper span {
    display: none;
  }

  span.flatpickr-weekday {
    @apply text-slate-400 font-medium text-xs;
  }

  .flatpickr-calendar.arrowTop::before,
  .flatpickr-calendar.arrowTop::after,
  .flatpickr-calendar.arrowBottom::before,
  .flatpickr-calendar.arrowBottom::after {
    display: none;
  }
}

/* .connections::-webkit-scrollbar {
    display: none;
}

.connections {
    -ms-overflow-style: none;
    scrollbar-width: none;
} */

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* react date picker */
.react-date-picker {
  display: inline-flex;
  position: relative;
  height: 45px;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid rgb(227, 227, 227);
  border-radius: 10px;
  padding: 5px 7px;
  background-color: #fbfbfb;
}

.react-date-picker__inputGroup {
  min-width: calc((3px * 3) + 0.54em + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-date-picker__inputGroup__divider {
  /* height: 10px; */
  padding: 1px 0;
  color: rgb(219, 219, 219);
  white-space: pre;
}

.react-date-picker__inputGroup__input {
  color: rgb(106, 106, 106);
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  font-size: 14px;
  box-sizing: content-box;
  -moz-appearance: none !important;
  appearance: none !important;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  /* background: rgba(255, 0, 0, 0.1); */
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
  /* color: #989898; */
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button__icon {
  height: 15px;
  stroke: #b1b1b1;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #a55eea;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 300px;
  max-width: 100vw;
  z-index: 1;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-calendar {
  width: 300px;
  max-width: 100%;
  background: white;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #ff8888;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ecdaff;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #a55eea;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-date-picker__clear-button__icon {
  display: none !important;
}
